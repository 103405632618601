(function($) {
    function addCustomBoxes() {
        $('.frm_form_field').find('input[type=radio], input[type=checkbox]').after('<span class="pseudo-input" />');
        $('.searchandfilter').find('input[type=radio], input[type=checkbox]').after('<span class="pseudo-input" />');
        $('.gift-wrapping').find('input[type=radio], input[type=checkbox]').after('<span class="pseudo-input" />');
        $('.wc-pao-addon-wrap').find('input[type=radio], input[type=checkbox]').after('<span class="pseudo-input" />');
    }

    $(document).ready(function($) {
        // addCustomBoxes();

        $('.frm_add_form_row').on('click', function() {
            setInterval(function(){
                // addCustomBoxes();
            }, 1000);
        });
    });
})(jQuery);
